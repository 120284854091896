ion-tab-bar {
  --border: none;
  box-shadow: var(--ion-tab-bar-box-shadow);
  // This height needed because ion-tab-bar is an absolute layout (root
  // layout), so it MUST have a declared height
  height: 72px;

  ion-tab-button {
    font-weight: #{$font-weight-semi-bold};
  }
}
