ion-header {
  --color: white;
  --ion-toolbar-color: white;

  .header-logo {
    font-size: 4rem;
    width: 2.5em;
  }

  ion-button {

    &::part(native) {
      --padding-start: .125rem;
      --padding-end: .125rem;
    }
  }
}

app-sections-page {
  ion-header {
    --ion-toolbar-background: var(--ion-background-color);
    --color: var(--ion-color-primary);
    --ion-toolbar-color: var(--ion-color-primary);
  }
}
