ion-card {
  --ion-box-shadow-color: #{rgba(($black), $opacity-level-medium)};
  --color: var(--ion-text-color);
  border-radius: var(--ion-card-border-radius, .25rem);
  box-shadow: #{$box-shadow-lg};
  margin: 0 0 2rem 0;

  &-subtitle {
    --color: var(--ion-text-color);
    font-weight: var(--font-weight-bold);
    text-transform: uppercase;
  }

  &-title {
    color: var(--ion-color-base, var(--ion-color-primary));
  }

  ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
    --inner-border-width: 0;

    ion-icon {

      &[slot="start"] {
        margin-right: .5rem;
      }
    }
  }
}
