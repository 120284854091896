form {

  ion-item {
    --padding-start: var(--form-ion-item-padding-inline);
    --inner-padding-end: var(--form-ion-item-padding-inline);
    --inner-border-width: var(--form-ion-item-border-width);
    margin-bottom: 1rem;
  }

  .item-label-stacked,
  .item-label-floating {

    .sc-ion-input-ios-h {
      --padding-start: .5rem;
    }
  }

  ion-label {
    display: inline-block;
    font-weight: bold;
    margin-bottom: .5rem;
  }

  ion-input {
    border: var(--ion-input-border-width) var(--ion-input-border-style);
    border-radius: var(--ion-input-border-radius);

    &.sc-ion-input-md-h,
    &.sc-ion-input-ios-h {
      --background: white;
      --color: #{$body-color};
    }
  }

  ion-checkbox {
    --border-radius: var(--ion-checkbox-border-radius);
  }

  ion-select {
    --padding-start: 10px;
    color: var(--ion-color-primary);
    border: var(--ion-select-border-width) var(--ion-select-border-style);
    border-radius: var(--ion-select-border-radius);

    &::part(text),
    &::part(placeholder) {
      text-align: center;
    }

    &::part(icon),
    &::part(placeholder) {
      opacity: 1;
    }
  }

  &.over-color {

    ion-item {
      --background: var(--form-ion-item-bg-over-color);
      --color: var(--form-ion-item-color-over-color);
    }

    ion-input {
      --color: var(--ion-input-color-over-color);
      --placeholder-color: var(--ion-input-placeholder-color-over-color);
    }

    ion-checkbox {
      --checkbox-background: var(--ion-checkbox-bg-over-color);
      --border-color: var(--ion-checkbox-border-color-over-color);
    }
  }
}
