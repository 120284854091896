ion-list {

  ion-item {
    --inner-padding-end: var(--ion-list-item-padding-x);
    --inner-padding-start: var(--ion-list-item-padding-x);
    --padding-start: var(--ion-list-item-padding-x);
    --inner-border-width: var(--ion-list-item-border-width);
    --min-height: 2rem;
    text-align: center;
  }
}
