ion-toolbar {
  --ion-padding: var(--ion-toolbar-inline-padding);
  --ion-margin: var(--ion-toolbar-inline-margin);
  --padding-top: var(--ion-toolbar-padding-y);
  --padding-end: var(--ion-toolbar-padding-x);
  --padding-bottom: var(--ion-toolbar-padding-y);
  --padding-start: var(--ion-toolbar-padding-x);

  ion-title {
    text-align: center;

    &.ios {
      padding-inline: 50px;
    }
  }
}
