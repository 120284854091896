ion-modal {

  &::part(handle) {
    height: 2px;
    width: 50px;
    top: 16px;
  }

  ion-content {
    --background: var(--ion-color-white);
    --padding-top: 2rem;
    --padding-bottom: 2rem;
    --padding-start: 1rem;
    --padding-end: 1rem;

    form {

      ion-input {
        text-align: center;

        &.sc-ion-input-ios-h {
          --padding-start: 10px;
        }
      }
    }
  }

  &.height-auto {
    --height: auto;
  }

  &.no-handle {

    &::part(handle) {
      display: none;
    }
  }
}
