.fw-regular {
  font-weight: $font-weight-regular;
}

.fw-bold {
  font-weight: $font-weight-bold;
}

.fw-extra-bold {
  font-weight: $font-weight-extra-bold;
}

.ff-body {
  font-family: $default-font-family;
}

.ff-heading {
  font-family: $headings-font-family;
}

.text-transform {

  &-initial {
    text-transform: initial;
  }
}
