.m {

  &x {

    &-auto {
      margin-left: auto;
      margin-right: auto;
      margin-inline: auto;
    }
  }
}
