ion-img {

  &::part(image) {
    object-fit: cover;
  }

  &:not([src]) {

    &::part(image) {
      background: var(--ion-color-light-shade);
      width: 100%;
      height: 100%;
    }
  }

  &.img {

    &-circle {

      &::part(image) {
        border-radius: var(--theme-border-radius-circle);
      }
    }
  }
}
