// Font Weight
$font-weight-thin: 100;
$font-weight-extra-light: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-extra-bold: 800;
$font-weight-black: 900;

// Font Size
$font-size-xs: .75rem;
$font-size-sm: .875rem;
$default-font-size: 1rem;

// Font Family
$default-font-family: "IBM Plex Sans", sans-serif;

// Headings
$headings-font-family: "Volkhov", serif;
$headings-font-weight: $font-weight-bold;
$h4-font-size: 1.125rem;

// CSS

:root {
  --font-weight-thin: #{$font-weight-thin};
  --font-weight-extra-light: #{$font-weight-extra-light};
  --font-weight-light: #{$font-weight-light};
  --font-weight-regular: #{$font-weight-regular};
  --font-weight-medium: #{$font-weight-medium};
  --font-weight-semi-bold: #{$font-weight-semi-bold};
  --font-weight-bold: #{$font-weight-bold};
  --font-weight-extra-bold: #{$font-weight-extra-bold};
  --font-weight-black: #{$font-weight-black};

  --font-size-xs: #{$font-size-xs};
  --font-size-sm: #{$font-size-sm};
  --h4-font-size: #{$h4-font-size};

  --headings-font-family: #{$headings-font-family};
  --headings-font-weight: #{$headings-font-weight};
}

