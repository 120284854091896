// Body
$body-color: tint-color($black, $shade-level-1);
$body-color-rgb: tint-color(to-rgb($black, true), $shade-level-1);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #{$chambray};
  --ion-color-primary-rgb: #{to-rgb($chambray)};
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #{shade-color($chambray)};
  --ion-color-primary-tint: #{tint-color($chambray)};

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #{$success};
  --ion-color-success-rgb: #{to-rgb($success)};
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #{shade-color($success)};
  --ion-color-success-tint: #{tint-color($success)};

  /** warning **/
  --ion-color-warning: #{$warning};
  --ion-color-warning-rgb: #{to-rgb($warning)};
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #{shade-color($warning)};
  --ion-color-warning-tint: #{tint-color($warning)};

  /** danger **/
  --ion-color-danger: #{$danger};
  --ion-color-danger-rgb: #{to-rgb($danger)};
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #{shade-color($danger)};
  --ion-color-danger-tint: #{tint-color($danger)};

  /** dark **/
  --ion-color-dark: #{$body-color};
  --ion-color-dark-rgb: #{$body-color-rgb};
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #{shade-color($body-color)};
  --ion-color-dark-tint: #{tint-color($body-color)};

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  // Ionic App Theme CSS Variables
  --ion-text-color: #{$body-color}; // Text color of the entire app
  --ion-text-color-rgb: #{$body-color-rgb}; // Text color of the entire app, rgb format
  --ion-background-color: #{$white}; // Background color of the entire app
  //--ion-background-color-rgb: ""; // Background color of the entire app, rgb format
  //--ion-backdrop-color: ""; // Color of the Backdrop component
  //--ion-backdrop-opacity: ""; // Opacity of the Backdrop component
  //--ion-overlay-background-color: ""; // Background color of the overlays
  //--ion-border-color: ""; // Border color
  --ion-box-shadow-color: #{rgba($primary, .64)}; // Box shadow color
  //--ion-tab-bar-background: ""; // Background of the Tab Bar
  //--ion-tab-bar-background-focused: ""; // Background of the focused Tab Bar
  //--ion-tab-bar-border-color: ""; // Border color of the Tab Bar
  //--ion-tab-bar-color: ""; // Color of the Tab Bar
  //--ion-tab-bar-color-selected: ""; // Color of the selected Tab Button
  --ion-toolbar-background: var(--ion-color-primary); // Background of the Toolbar
  //--ion-toolbar-border-color: ""; // Border color of the Toolbar
  //--ion-toolbar-color: var(--ion-text-color); // Color of the components in the Toolbar
  //--ion-toolbar-segment-color: ""; // Color of the Segment Buttons in the Toolbar
  //--ion-toolbar-segment-color-checked: ""; // Color of the checked Segment Buttons in the Toolbar
  //--ion-toolbar-segment-background: ""; // Background of the Segment Buttons in the Toolbar
  //--ion-toolbar-segment-background-checked: ""; // Background of the Segment Buttons in the Toolbar
  //--ion-toolbar-segment-indicator-color: ""; // Color of the Segment Button indicator in the Toolbar
  //--ion-item-background: ""; // Background of the Item
  //--ion-item-border-color: ""; // Border color of the Item
  //--ion-item-color: ""; // Color of the components in the Item
  //--ion-placeholder-color: ""; // Color of the placeholder in Inputs

  // App Theme CSS Variables - this variables is not default Ionic theme
  // it's from F&MD App Starter
  --theme-border-radius: #{$border-radius};
  --theme-border-radius-circle: #{$border-radius-pill};
  --theme-border-width: #{$border-width};
  --theme-border-style: solid;

  // ion-button
  --ion-button-font-weight: #{$font-weight-semi-bold};
  --ion-button-font-size: #{$default-font-size};
  --ion-button-text-transform: initial;
  --ion-button-border-radius: var(--theme-border-radius);
  --ion-button-border-radius-circle: #{$border-radius-pill};
  --ion-button-box-shadow: #{$box-shadow};
  --ion-button-padding-x: 1.5rem;
  --ion-button-padding-y: .75rem;

  // ion-badge
  --ion-badge-border-radius: #{$border-radius-pill};
  --ion-badge-padding-y: .5rem;
  --ion-badge-padding-x: 1rem;

  // ion-card
  --ion-card-border-radius: .25rem;

  // ion-toolbar
  --ion-toolbar-padding-x: .5rem;
  --ion-toolbar-padding-y: .5rem;
  //--ion-toolbar-inline-padding: .5rem;
  //--ion-toolbar-inline-margin: .5rem;

  // ion-segment
  --ion-segment-color: var(--ion-text-color-rgb);
  --ion-segment-color-checked: var(--ion-text-color-rgb);
  --ion-segment-indicator-color: var(--ion-color-primary);

  // ion-tab-bar
  --ion-tab-bar-box-shadow: #{$box-shadow};

  // ion-list
  --ion-list-item-border-width: 0;
  --ion-list-item-padding-x: 0;

  // ion-accordion
  --ion-accordion-active-color: var(--ion-color-primary);

  // Forms
  --form-input-border-width: var(--theme-border-width);
  --form-input-border-style: var(--theme-border-style);
  --form-input-border-radius: var(--theme-border-radius);
  --form-input-bg-over-color: transparent;

  // ion-item
  --form-ion-item-padding-inline: 0;
  --form-ion-item-border-width: 0;
  --form-ion-item-bg-over-color: var(--form-input-bg-over-color);
  --form-ion-item-color-over-color: var(--ion-color-white);

  // ion-input
  --ion-input-border-width: var(--form-input-border-width);
  --ion-input-border-style: var(--form-input-border-style);
  --ion-input-border-radius: var(--form-input-border-radius);
  --ion-input-color-over-color: var(--ion-color-white);
  --ion-input-placeholder-color-over-color: var(--ion-color-white);

  // ion-checkbox
  --ion-checkbox-border-width: var(--form-input-border-width);
  --ion-checkbox-border-style: var(--form-input-border-style);
  --ion-checkbox-border-radius: var(--form-input-border-radius);
  --ion-checkbox-bg-over-color: var(--form-input-bg-over-color);
  --ion-checkbox-border-color-over-color: inherit;

  // ion-select
  --ion-select-border-width: var(--form-input-border-width);
  --ion-select-border-style: var(--form-input-border-style);
  --ion-select-border-radius: var(--form-input-border-radius);
}

//@media (prefers-color-scheme: dark) {
//  /*
//   * Dark Colors
//   * -------------------------------------------
//   */
//
//  body {
//    --ion-color-primary: #428cff;
//    --ion-color-primary-rgb: 66,140,255;
//    --ion-color-primary-contrast: #ffffff;
//    --ion-color-primary-contrast-rgb: 255,255,255;
//    --ion-color-primary-shade: #3a7be0;
//    --ion-color-primary-tint: #5598ff;
//
//    --ion-color-secondary: #50c8ff;
//    --ion-color-secondary-rgb: 80,200,255;
//    --ion-color-secondary-contrast: #ffffff;
//    --ion-color-secondary-contrast-rgb: 255,255,255;
//    --ion-color-secondary-shade: #46b0e0;
//    --ion-color-secondary-tint: #62ceff;
//
//    --ion-color-tertiary: #6a64ff;
//    --ion-color-tertiary-rgb: 106,100,255;
//    --ion-color-tertiary-contrast: #ffffff;
//    --ion-color-tertiary-contrast-rgb: 255,255,255;
//    --ion-color-tertiary-shade: #5d58e0;
//    --ion-color-tertiary-tint: #7974ff;
//
//    --ion-color-success: #2fdf75;
//    --ion-color-success-rgb: 47,223,117;
//    --ion-color-success-contrast: #000000;
//    --ion-color-success-contrast-rgb: 0,0,0;
//    --ion-color-success-shade: #29c467;
//    --ion-color-success-tint: #44e283;
//
//    --ion-color-warning: #ffd534;
//    --ion-color-warning-rgb: 255,213,52;
//    --ion-color-warning-contrast: #000000;
//    --ion-color-warning-contrast-rgb: 0,0,0;
//    --ion-color-warning-shade: #e0bb2e;
//    --ion-color-warning-tint: #ffd948;
//
//    --ion-color-danger: #ff4961;
//    --ion-color-danger-rgb: 255,73,97;
//    --ion-color-danger-contrast: #ffffff;
//    --ion-color-danger-contrast-rgb: 255,255,255;
//    --ion-color-danger-shade: #e04055;
//    --ion-color-danger-tint: #ff5b71;
//
//    --ion-color-dark: #f4f5f8;
//    --ion-color-dark-rgb: 244,245,248;
//    --ion-color-dark-contrast: #000000;
//    --ion-color-dark-contrast-rgb: 0,0,0;
//    --ion-color-dark-shade: #d7d8da;
//    --ion-color-dark-tint: #f5f6f9;
//
//    --ion-color-medium: #989aa2;
//    --ion-color-medium-rgb: 152,154,162;
//    --ion-color-medium-contrast: #000000;
//    --ion-color-medium-contrast-rgb: 0,0,0;
//    --ion-color-medium-shade: #86888f;
//    --ion-color-medium-tint: #a2a4ab;
//
//    --ion-color-light: #222428;
//    --ion-color-light-rgb: 34,36,40;
//    --ion-color-light-contrast: #ffffff;
//    --ion-color-light-contrast-rgb: 255,255,255;
//    --ion-color-light-shade: #1e2023;
//    --ion-color-light-tint: #383a3e;
//  }
//
//  /*
//   * iOS Dark Theme
//   * -------------------------------------------
//   */
//
//  .ios body {
//    --ion-background-color: #000000;
//    --ion-background-color-rgb: 0,0,0;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255,255,255;
//
//    --ion-color-step-50: #0d0d0d;
//    --ion-color-step-100: #1a1a1a;
//    --ion-color-step-150: #262626;
//    --ion-color-step-200: #333333;
//    --ion-color-step-250: #404040;
//    --ion-color-step-300: #4d4d4d;
//    --ion-color-step-350: #595959;
//    --ion-color-step-400: #666666;
//    --ion-color-step-450: #737373;
//    --ion-color-step-500: #808080;
//    --ion-color-step-550: #8c8c8c;
//    --ion-color-step-600: #999999;
//    --ion-color-step-650: #a6a6a6;
//    --ion-color-step-700: #b3b3b3;
//    --ion-color-step-750: #bfbfbf;
//    --ion-color-step-800: #cccccc;
//    --ion-color-step-850: #d9d9d9;
//    --ion-color-step-900: #e6e6e6;
//    --ion-color-step-950: #f2f2f2;
//
//    --ion-item-background: #000000;
//
//    --ion-card-background: #1c1c1d;
//  }
//
//  .ios ion-modal {
//    --ion-background-color: var(--ion-color-step-100);
//    --ion-toolbar-background: var(--ion-color-step-150);
//    --ion-toolbar-border-color: var(--ion-color-step-250);
//  }
//
//
//  /*
//   * Material Design Dark Theme
//   * -------------------------------------------
//   */
//
//  .md body {
//    --ion-background-color: #121212;
//    --ion-background-color-rgb: 18,18,18;
//
//    --ion-text-color: #ffffff;
//    --ion-text-color-rgb: 255,255,255;
//
//    --ion-border-color: #222222;
//
//    --ion-color-step-50: #1e1e1e;
//    --ion-color-step-100: #2a2a2a;
//    --ion-color-step-150: #363636;
//    --ion-color-step-200: #414141;
//    --ion-color-step-250: #4d4d4d;
//    --ion-color-step-300: #595959;
//    --ion-color-step-350: #656565;
//    --ion-color-step-400: #717171;
//    --ion-color-step-450: #7d7d7d;
//    --ion-color-step-500: #898989;
//    --ion-color-step-550: #949494;
//    --ion-color-step-600: #a0a0a0;
//    --ion-color-step-650: #acacac;
//    --ion-color-step-700: #b8b8b8;
//    --ion-color-step-750: #c4c4c4;
//    --ion-color-step-800: #d0d0d0;
//    --ion-color-step-850: #dbdbdb;
//    --ion-color-step-900: #e7e7e7;
//    --ion-color-step-950: #f3f3f3;
//
//    --ion-item-background: #1e1e1e;
//
//    --ion-toolbar-background: #1f1f1f;
//
//    --ion-tab-bar-background: #1f1f1f;
//
//    --ion-card-background: #1e1e1e;
//  }
//}
