// Colors
$black: #000000;
$white: #ffffff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;

// Theme Colors
$chambray: #415195;
$emerald: #52bc7d;
$pumpkin: #ff7e20;
$carnation: #aa0418;
$tamarillo: #a71830;

// sections (Categories) Colors
$blue-chill: #499b5e;
$deluge: #584980;
$tango: #d36b3d;
$hollywood-cerise: #ee0e92;
$medium-red-violet: #c42d78;
$japanese-maple: #7a0202;
$viking: #78bedf;
$flame-pea: #da522c;
$cardinal: #d2232a;

// --------------------------------------------------------

// Theme variables
$primary: $chambray;
$success: $emerald;
$warning: $pumpkin;
$danger: $carnation;
$info: $tamarillo;

// sections (Categories) Theme
$sections-theme-cities: $blue-chill;
$sections-theme-brazil-and-world: $deluge;
$sections-theme-sports: $tango;
$sections-theme-culture: $hollywood-cerise;
$sections-theme-social: $medium-red-violet;
$sections-theme-more: $japanese-maple;
$sections-theme-blog: $viking;
$sections-theme-magazine: $flame-pea;
$sections-theme-classimais: $cardinal;

// JUST FOR DEVELOPMENT PURPOSE
:root {
  --sections-theme-classimais: #{$sections-theme-classimais};
  --sections-theme-blog: #{$sections-theme-blog};
}
