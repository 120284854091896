@import 'node_modules/swiper/swiper.scss';
@import 'node_modules/swiper/modules/free-mode/free-mode.scss';
@import 'node_modules/swiper/modules/thumbs/thumbs.scss';
@import 'node_modules/swiper/modules/pagination/pagination.scss';

:root,
.swiper {
  --swiper-theme-color: var(--ion-color-primary);
  --swiper-pagination-color: #{tint-color($primary, $tint-level-2)};
  //--swiper-pagination-bullet-width: 3rem;
  //--swiper-pagination-bullet-height: .5rem;
  --swiper-pagination-bullet-inactive-color: var(--ion-color-primary-contrast);
  --swiper-pagination-bullet-inactive-opacity: 1;
  //--bullet-background: var(--ion-color-white);
  //--swiper-pagination-bullet-horizontal-gap: 3px;
  --swiper-navigation-size: 2rem;
  --swiper-navigation-color: var(--ion-color-primary-contrast);
}

swiper,
.swiper {

  & &-slide {

    img {
    }
  }

  &-thumbs {

    swiper-slide,
    .swiper-slide {
      width: auto;
      cursor: pointer;

      ion-label {
        display: inline-block;
        padding: .8rem;
      }

      &::after {
        content: '';
        display: block;
        opacity: 0;
        height: 2px;
        width: 100%;
        background-color: var(--ion-color-primary);
      }

      &-thumb-active {
        font-weight: var(--font-weight-bold);

        &::after {
          opacity: 1;
        }
      }
    }
  }

  &-pagination {

    &-bullet {
      transition: all .35s ease-in-out;
      border-radius: 1.5rem;

      &-active {
        --swiper-pagination-bullet-width: 3rem;
      }
    }
  }

  &-button {

    &-prev,
    &-next {
      bottom: 1rem;
      top: unset;
      // overflow pagination z-index (10)
      z-index: 11;
    }
  }

  &-horizontal {

    .swiper-pagination {
      bottom: 1.375rem;
    }
  }
}
