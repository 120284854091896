h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--headings-font-family);
  font-weight: var(--headings-font-weight);

  &:first-child,
  &:first-of-type {

    &:not(.ion-margin-top):not(.ion-margin-vertical) {
      margin-top: 0;
    }
  }

  &:only-child {
    margin-bottom: 0;
  }
}

h4 {
  font-size: var(--h4-font-size);
}

// Paragraphs

p {

  line-height: 1.6em;

  &:last-child,
  &:last-of-type {

    &:not(.ion-margin-bottom):not(.ion-margin-vertical) {
      margin-bottom: 0;
    }
  }
}
