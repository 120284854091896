.ion-no-border {
  --border-width: 0;
  --inner-border-width: 0;
}

hr {
  display: inline-block;
  width: 100%;
  background: var(--ion-color-light-shade);
}
