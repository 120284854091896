figure {
  margin: 0;

  &.wp-block-image:not(:last-child) {
    margin-bottom: 1rem;
  }
}

figcaption {
  font-size: .75rem;
}

ul {
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 1rem;
  }
}
