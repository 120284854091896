$flex-grow-relations: (
  1:1,
  '1q':1.5,
  2:2,
  3:3,
  4:4,
  5:5,
);

@each $key, $value in $flex-grow-relations {
  .flex-grow-#{$key} {
    flex-grow: #{$value};
  }
}
