ion-accordion {

  margin-bottom: 1rem;
  background-color: var(--ion-background-color, #ffffff);


  &[slot="header"] {

  }

  &[slot="content"] {

  }

  &.accordion-expanded,
  &.accordion-expanding {

    [slot="header"] {
      --ion-item-color: var(--ion-accordion-active-color);
      font-weight: $font-weight-bold;
    }
  }

  //header	The wrapper element for the header slot.
  &::part(header) {

  }

  &::part(header expanded) {

  }

  //content	The wrapper element for the content slot.
  &::part(content) {
    --padding-start: 0;
    --inner-padding-end: 0;
  }

  &::part(content expanded) {
  }

  //expanded  The expanded element. Can be used in combination with the header and content parts (i.e. ::part(header expanded)).
  &::part(expanded) {
    border-bottom: var(--theme-border-width) solid var(--ion-accordion-active-color);
  }
}
