ion-button {

  text-transform: var(--ion-button-text-transform);
  font-weight: var(--ion-button-font-weight);

  &:not(.ion-no-padding):not(.button-small):not(.button-large) {
    height: auto;
    font-size: var(--ion-button-font-size);
    --padding-top: var(--ion-button-padding-y);
    --padding-end: var(--ion-button-padding-x);
    --padding-bottom: var(--ion-button-padding-y);
    --padding-start: var(--ion-button-padding-x);
  }

  &:not([shape="round"]) {
    --border-radius: var(--ion-button-border-radius);
  }

  &[shape="round"] {
    --border-radius: var(--ion-button-border-radius-circle);
  }

  // App specifics styles
  &:not(.no-shadow):not([fill="clear"]):not([fill="outline"]) {
    --box-shadow: var(--ion-button-box-shadow);

    &.ion-activated {
      --box-shadow: #{$box-shadow-activated};
    }
  }

  &.only-icon {

    ion-icon {
      font-size: 1.3em;
      padding: .5em;
    }
  }
}
