ion-segment {

  &.no-feedback {

    ion-segment-button {
      --color: initial;
      --color-checked: initial;
      --indicator-color: initial;
      font-weight: initial;
    }
  }

  &-button {
    text-transform: initial;
    --color: var(--ion-segment-color);
    --color-checked: var(--ion-segment-color-checked);
    --indicator-color: var(--ion-segment-indicator-color);

    &.segment-button-checked {
      font-weight: var(--font-weight-bold);
    }
  }
}
